<template>
    <section id="case-studies-home" class="relative pb-36">
        <div class="overflow-hidden absolute w-full h-full pointer-events-none top-0">
            <div class="pink-radial absolute"></div>
            <div class="pink-radial absolute"></div>
            <div class="white-radial absolute"></div>
            <div class="white-radial absolute"></div>
        </div>
        <div class="main-wrapper pt-24">
            <p class="lg:text-4xl text-3xl brother gradient-clip max-w-xl mb-12">{{ $t('pages.caseStudies.let_us_show_you') }} <span>{{ $t('pages.caseStudies.exceeded') }}</span> {{ $t('pages.caseStudies.our_customers_expectations') }}</p>
            <LazySectionsGeneralPortfolio
                    theme="portfolio-dark"
                    :show-title="false"
                    :perPage="16"
                    :no-padding="true"
                    :link="true"
            />
        </div>
    </section>
</template>
<script setup>

</script>
